<!-- 
    页面 人脸管理 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="face-list-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- <el-row class="search-row" type="flex">
          <el-col :span="18">
            <el-input class="search-input" size="mini" v-model="selectOption.device_name" placeholder="请输入设备名称"></el-input>
            <el-input class="search-input" size="mini" v-model="selectOption.io_name" placeholder="请输入点位名称"></el-input>
            <el-input class="search-input" size="mini" v-model="selectOption.people_name" placeholder="请输入姓名"></el-input>
            <el-button class="search-btn" size="mini" @click="searchAction">搜索</el-button>
          </el-col>
        </el-row> -->
        <!-- 整体表 -->
        <el-table class="table_theme" :data="array" ref="rolesTable" row-key="device_code" v-loading="loading">
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
              <span>{{ (pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column property="userName" label="提交人" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ $cFun.encryptName(scope.row.userName) }}</p>
            </template>
          </el-table-column>
          <el-table-column property="mobile" label="手机号" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ $cFun.encryptPhone(scope.row.mobile) }}</p>
            </template>
          </el-table-column>
          <el-table-column property="certificate_type" label="证件类型" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column property="certificate_no" label="证件号码" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.certificate_type == '身份证' ? $cFun.encryptIDNumber(scope.row.certificate_no, 1) : $cFun.encryptIDNumber(scope.row.certificate_no, 2) }}</p>
            </template>
          </el-table-column>
          <el-table-column property="house_address" label="场所名称" show-overflow-tooltip></el-table-column>
          <el-table-column property="approve_state" label="审核状态" width="150" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.approve_state == 0" type="warning">待审核</el-tag>
              <el-tag v-if="scope.row.approve_state == 1" type="success">审核通过</el-tag>
              <el-tag v-if="scope.row.approve_state == 2" type="warning">审核未通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="48" header-align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.approve_state == 0" type="text" size="small" class="primary" @click="checkInfo(scope.row)">审核</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination class="pagination" layout="total, sizes, prev, pager, next" prev-text="上一页" next-text="下一页" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageOpt.currentPage" :page-sizes="pageOpt.pageSizes" :page-size="pageOpt.currentPageSize" :pager-count="pageOpt.pageCount" :total="pageOpt.totalPageSize" :hide-on-single-page="pageOpt.hideSinglePage"></el-pagination>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
  data() {
    return {
      village_name: '',
      // 筛选状态
      selectOption: {
        village_code: this.$store.state.auth.space.space_code || ''
      },
      // 渲染数据
      array: [],
      relationReturnDataAll: [],
      // 加载状态
      height: this.$store.state.windowHeight,
      currentIndex: null,
      drawer: false,
      loading: false,
      drawerLoading: false,
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    }
  },
  watch: {
    '$store.state.auth.space.space_code'(val) {
      this.village_name = this.$store.state.auth.space.space_name
      this.selectOption.village_code = val
      this.getPeopleHouseList()
    }
  },
  methods: {
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1 + (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val
      this.getPeopleHouseList()
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val
      this.getPeopleHouseList()
    },
    // 审核
    async checkInfo(row) {
      console.log(row)
      try {
        await this.$confirm(`<div> 姓名: ${row.userName} <br> 手机号: ${row.mobile} <br> ${row.certificate_type}: ${row.certificate_no} <br> 申请地址: ${row.house_address} </div>`, '审核', {
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          confirmButtonText: '通过',
          cancelButtonText: '拒绝',
          customClass: 'button-group',
          type: 'warning',
          center: true
        })
          .then(async () => {
            // 审核通过
            try {
              console.log('审核通过', row)
              let peopleid = ''
              // 先查询人是否存在
              let peopleList = await this.getPeopleList(row.certificate_no)
              if (peopleList.length > 0) {
                peopleid = peopleList[0].people_id
              } else {
                // 先添加人
                peopleid = await this.addPeople(row)
              }
              // 再添加人房关系
              let isOk = await this.addPeopleHouse(peopleid, row)
              // 提交审核
              if (isOk) {
                let param = { id: row.id, approve_state: '1' }
                let result = await this.$apis.ODC('更新审核状态', param)
                if (this.$apis.isOk(result)) {
                  this.$cFun.showNotify(this.$vs.notify, 'success', '审核成功')
                  this.getPeopleHouseList()
                } else {
                  this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
                }
              }
            } catch (err) {
              this.$message({ type: 'danger', message: err.message ? err.message : err })
            }
          })
          .catch(async action => {
            if (action === 'close') return false
            // 审核拒绝
            // 提交审核
            let param = {
              id: row.id,
              approve_state: '2'
            }
            let result = await this.$apis.ODC('更新审核状态', param)
            if (this.$apis.isOk(result)) {
              this.$cFun.showNotify(this.$vs.notify, 'success', '审核成功')
              this.getPeopleHouseList()
            } else {
              this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
            }
            try {
              console.log('审核拒绝')
            } catch (err) {
              this.$message({ type: 'danger', message: err.message ? err.message : err })
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    // 查询人员信息是否存在
    async getPeopleList(certificate_no) {
      try {
        let param = {
          certificate_no: certificate_no,
          pageindex: 1,
          pagesize: 10
        }
        let result = await this.$apis.ODC('获取住户列表', param)
        if (this.$apis.isOk(result)) {
          return result.PeopleList
          // this.peopleArr = this.recurisonMenu(result.PeopleList)
          // this.pageOpt.totalPageSize = result.data.count;
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
      this.loading = false
    },
    // 身份证获取年月日
    IdCard(IdCard, type) {
      if (type === 1) {
        //获取出生日期
        let birthday = IdCard.substring(6, 10) + '-' + IdCard.substring(10, 12) + '-' + IdCard.substring(12, 14)
        return birthday
      }
      if (type === 2) {
        //获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
          return '男'
        } else {
          return '女'
        }
      }
      if (type === 3) {
        //获取年龄
        var ageDate = new Date()
        var month = ageDate.getMonth() + 1
        var day = ageDate.getDate()
        var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || (IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day)) {
          age++
        }
        if (age <= 0) {
          age = 1
        }
        return age
      }
    },
    // 保存人员信息
    async addPeople(row) {
      try {
        // 证件类型
        let certificate_type = row.certificate_type == '身份证' ? '2000' : '2099'
        // 证件号码
        let certificate_no = row.certificate_no
        // 出生日期
        let birthDate = certificate_type == '2000' ? this.IdCard(certificate_no, 1) : this.$cFun.formatTime(new Date().getTime())
        // 性别
        let sexName = certificate_type == '2000' ? this.IdCard(certificate_no, 2) : '未知'
        // 提交信息
        let params = new Object()
        params['people'] = {
          certificate_no: row.certificate_no,
          people_name: row.userName,
          mobile_phone: row.mobile,
          people_type: '',
          certificate_type: certificate_type,
          certificate_validity: this.$cFun.formatTime(new Date().getTime() + 24 * 60 * 60 * 1000),
          sex: sexName == '未知' ? 99 : sexName == '男' ? 1 : 2,
          nation: '汉族',
          birth_date: birthDate,
          origin: '其他地区',
          domiclle_detail_address: '',
          domiclle_address: '',
          residence_detail_address: '',
          residence_address: '',
          education: '其他',
          political: '群众',
          marital_status: '未知',
          nationality: '中国',
          id_pic: '',
          head_fea: '',
          head_pic: '',
          live_fea: '',
          live_pic: ''
        }
        let result = await this.$apis.ODC('新增人员', params)
        return result.peopleID
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 保存人房信息
    async addPeopleHouse(people_id, row) {
      let houseUseArr = _.find(this.relationReturnDataAll, { using_type: 'db/people/reside_mode' }).extend
      let reside_mode = _.find(houseUseArr, { name: row.space_using_type }) ? _.find(houseUseArr, { name: row.space_using_type }).code : 6
      try {
        let params = new Object()
        params = {
          unit_code: row.unit_code,
          house_code: row.house_code,
          house_no: row.house_code.substring(row.house_code.length - 4, row.house_code.length),
          telephone: row.mobile,
          people_id: people_id,
          relation_with_house_master: 99,
          person_type: 99,
          reside_mode: String(reside_mode),
          auth_start_time: this.$cFun.formatTime(new Date()),
          auth_expire_time: this.$cFun.formatTime('2099-12-31 00:00:00'),
          main_call: false
        }
        let result = await this.$apis.ODC('保存人房关系', params)
        if (this.$apis.isOk(result)) {
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 递归添加 (改动小可以使用递归)
    recurisonMenu(array) {
      return array
    },
    // 获取设备信息
    async getPeopleHouseList() {
      this.loading = true
      try {
        let param = this.selectOption
        ;(param['pageNum'] = this.pageOpt.currentPage), (param['pageSize'] = this.pageOpt.currentPageSize)
        let result = await this.$apis.ODC('获取审核列表', param)
        if (this.$apis.isOk(result)) {
          this.array = this.recurisonMenu(result.list)
          this.pageOpt.totalPageSize = result.total_count
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
      this.loading = false
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 请求接口
    async searchAction() {
      this.pageOpt.currentPage = 1
      this.getPeopleHouseList()
    }
  },
  async created() {
    // 请求数据
    this.village_name = this.$store.state.auth.space.space_name
    this.getPeopleHouseList()
    this.relationReturnDataAll = await this.getRelationReturnDataALL(['db/people/reside_mode'])
  },
  components: {}
}
</script>

<style lang="scss" scope="scope">
.button-group {
  .el-button--primary {
    border-color: #dcdfe6;
    background-color: #f2f6fe !important;
  }
}
#face-list-page {
  .search-row {
    margin-bottom: 5px;
    .search-select {
      margin-right: 5px;
    }
    .search-input {
      width: 200px;
      margin-right: 5px;
    }
    .search-btn {
      &:hover {
        background-color: rgba(var(--vs-primary), 0.1);
        border-color: rgba(var(--vs-primary), 0.1);
      }
    }
  }
  .table_theme {
    .el-table__expanded-cell[class*='cell'] {
      padding: 0px;
    }
    .el-table__body tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
  .cardListDrawer {
    top: 60px;
    .el-drawer__header {
      margin-bottom: 0px;
    }
    .box-card {
      margin-bottom: 10px;
      .item {
        position: relative;
        height: 100px;
        .bottom {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
